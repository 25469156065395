const createAssetsBreadcrumb = [
	{
		text: 'Inicio',
		link: true,
		exact: true,
		disabled: false,
		to: {
			name: 'Dashboard',
		},
	},
	{
		text: 'Inventario',
		link: true,
		exact: true,
		disabled: false,
		to: {
			name: 'InventoryHome',
		},
	},
	{
		text: 'Activos',
		link: true,
		exact: true,
		disabled: false,
		to: {
			name: 'AssetsPermissions',
		},
	},
	{
		text: 'Registrar Activo',
		link: true,
		exact: true,
		disabled: false,
	},
];

const updateAssetsBreadcrumb = [
	{
		text: 'Inicio',
		link: true,
		exact: true,
		disabled: false,
		to: {
			name: 'Dashboard',
		},
	},
	{
		text: 'Inventario',
		link: true,
		exact: true,
		disabled: false,
		to: {
			name: 'InventoryHome',
		},
	},
	{
		text: 'Activos',
		link: true,
		exact: true,
		disabled: false,
		to: {
			name: 'AssetsPermissions',
		},
	},
	{
		text: 'Editar Activo',
		link: true,
		exact: true,
		disabled: false,
	},
];

const createSparesBreadcrumb = [
	{
		text: 'Inicio',
		link: true,
		exact: true,
		disabled: false,
		to: {
			name: 'Dashboard',
		},
	},
	{
		text: 'Inventario',
		link: true,
		exact: true,
		disabled: false,
		to: {
			name: 'InventoryHome',
		},
	},
	{
		text: 'Repuestos',
		link: true,
		exact: true,
		disabled: false,
		to: {
			name: 'SparesPermissions',
		},
	},
	{
		text: 'Registrar Repuesto',
		link: true,
		exact: true,
		disabled: false,
	},
];

const updateSparesBreadcrumb = [
	{
		text: 'Inicio',
		link: true,
		exact: true,
		disabled: false,
		to: {
			name: 'Dashboard',
		},
	},
	{
		text: 'Inventario',
		link: true,
		exact: true,
		disabled: false,
		to: {
			name: 'InventoryHome',
		},
	},
	{
		text: 'Repuestos',
		link: true,
		exact: true,
		disabled: false,
		to: {
			name: 'SparesPermissions',
		},
	},
	{
		text: 'Editar Repuesto',
		link: true,
		exact: true,
		disabled: false,
	},
];

const returnTicketBreadcrumb = [
	{
		text: 'Inicio',
		link: true,
		exact: true,
		disabled: false,
		to: {
			name: 'Dashboard',
		},
	},
	{
		text: 'Incidentes',
		link: true,
		exact: true,
		disabled: false,
		to: {
			name: 'IncidentsHome',
		},
	},
	{
		text: 'Atención de Tickets',
		link: true,
		exact: true,
		disabled: false,
		to: {
			name: 'RequestList',
		},
	},
	{
		text: 'Tickets de Devoluciones',
		link: true,
		exact: true,
		disabled: true,
	},
];

export {
	createAssetsBreadcrumb,
	updateAssetsBreadcrumb,
	createSparesBreadcrumb,
	updateSparesBreadcrumb,
	returnTicketBreadcrumb,
};
