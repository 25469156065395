import { mapState, mapActions } from 'vuex';
import ValidationAlert from '@/components/global/ValidationAlert.vue';
import { checkIntegerPart, integerRule, maxValueRule, requiredRule } from '@/helpers/validationList';
import moment from 'moment';
import {
	createSparesBreadcrumb,
	updateSparesBreadcrumb,
} from '@/helpers/listBreadcrumbs';

export default {
	name: 'SparesForm',
	data() {
		return {
			listError: [],
			valid: false,
			loadingSave: false,
			sparesForm: {
				name: '',
				area: null,
				serial_number: '',
				spare_type: null,
				// cost: null,

				//
				num_spare: null,
				tag: null,
				model: null,
				observations: null,
				purchase_date: null,
				purchase_value: null
			},
			requiredRules: [(v) => !!v || 'El campo es requerido'],
			nameRules: [
				(v) => !!v || 'Nombre de Activo es requerido',
				(v) => v.length <= 50 || 'El nombre debe tener menos de 50 carácteres',
			],
			areaRules: [(v) => !!v || 'Área es requerido'],
			serieRules: [
				(v) => !!v || 'Número de serie es requerido',
				(v) => v.length <= 50 || 'La serie debe tener menos de 50 carácteres',
			],
			numSpareRules: [
				(v) => requiredRule(v),
				(v) => integerRule(v),
				(v) => maxValueRule(9, v),
			],
			typeRules: [(v) => !!v || 'El tipo es requerido'],
			costRules: [
				(v) => !!v || 'Costo de activo es requerido',
				(v) =>
					checkIntegerPart(v) ||
					'La parte entera debe tener un máximo de 6 dígitos',
				(v) =>
					/^[0-9]*(\.[0-9]{0,2})?$/.test(v) ||
					'Solo se permiten números con hasta dos decimales',
			],
			// commentRules: [
			// 	(v) => !!v || 'El comentario es requerido',
			// 	(v) =>
			// 		!v ||
			// 		v.length <= 400 ||
			// 		'El comentario debe tener menos de 400 carácteres',
			// ],
		};
	},
	computed: {
		...mapState('area', ['allAreas']),
		...mapState('spareType', ['allSpareTypes']),
		...mapState('spare', ['spare']),
		...mapState({
			companies: (state) => state.auth.user?.companies,
			responsibles: (state) => state.security.usersActive,
		}),

		mode() {
			if (this.$route.name == 'SparesCreate') return 'CREATE';
			if (this.$route.name == 'SparesEdit') return 'UPDATE';
			return null;
		},
		breadcrumbs() {
			return this.$route.name == 'SparesCreate'
				? createSparesBreadcrumb
				: updateSparesBreadcrumb;
		},
	},
	created() {

		this.getUsersActive();
		if (this.mode === 'UPDATE') {
			this.getSpare();
		}
	},
	methods: {
		...mapActions('spare', ['createSpare', 'updateSpare', 'getSpareById']),
		...mapActions('security', ['getUsersActive']),

		setValues(spare) {
			if (spare) {
				spare = { ...spare };
				this.sparesForm = {
					...this.sparesForm,
					name: spare?.name,
					area: spare?.area?.id,
					serial_number: spare?.serial_number,
					spare_type: spare?.spare_type?.id,
					cost: spare?.cost,
					comment: null,
					//
					num_spare: spare?.num_spare,
					tag: spare?.tag,
					model: spare?.model,
					observations: spare?.observations,
					purchase_date: spare?.purchase_date ? moment(spare?.purchase_date).format('YYYY-MM-DD') : spare?.purchase_date,
					purchase_value: spare?.purchase_value
				};
			}
		},

		async getSpare() {
			const id = this.$route.params?.spareId;
			const { ok, response, error } = await this.getSpareById(id);
			if (ok) {
				this.setValues(response);
			} else {
				this.showError(error);
			}
		},

		async saveAsset() {
			this.listError = [];
			this.loadingSave = true;
			const purchase_value = parseFloat(this.sparesForm.purchase_value);
			const num_spare = parseFloat(this.sparesForm.num_spare);
			const data = {
				...this.sparesForm,
				purchase_value,
				num_spare
			};

			const { ok, error } = await this.createSpare(data);
			if (ok) {
				//this.$router.push({ name: this.$route.query.origin === 'detail' ? 'SparesPermissions' : 'DetailSpareInventory' });
				this.$router.push({ name: 'SparesPermissions' });
			} else {
				this.showError(error);
			}
			this.loadingSave = false;
		},

		async updateAsset() {

			this.listError = [];
			this.loadingSave = true;

			delete this.sparesForm.area;
			delete this.sparesForm.spare_type;
			delete this.sparesForm.comment;

			const purchase_value = parseFloat(this.sparesForm.purchase_value);
			const num_spare = parseFloat(this.sparesForm.num_spare);
			const data = {
				...this.sparesForm,
				purchase_value,
				num_spare
			};
			const { ok, error } = await this.updateSpare({
				id: this.spare.id,
				data: data,
			});
			if (ok) {
				this.$router.push({ name: this.$route.query.origin === 'detail' ? 'DetailSpareInventory' : 'SparesPermissions' });
			} else {
				this.showError(error);
			}
			this.loadingSave = false;
		},

		cancelSpareForm() {
			this.$router.push({ name: this.$route.query.origin === 'detail' ? 'DetailSpareInventory' : 'SparesPermissions' });
		},

		buttonClickHandler() {
			if (this.$refs?.form?.validate()) {
				if (this.mode === 'CREATE') this.saveAsset();
				if (this.mode === 'UPDATE') this.updateAsset();
			}
		},

		showError(error) {
			error = error?.data?.message;
			this.listError = Array.isArray(error) ? error : [error] || null;
		},
	},
	components: {
		ValidationAlert,
	},
};
